// Trello.js
import React from 'react';
import Sidebar from '../components/FlexFit/Sidebar';
// Import your image
import trelloimage from '../assets/images/trello_overview.png';
import trellodesign from '../assets/images/trello_design_thinking_system.png';
import trelloprotopersona from '../assets/images/trello_proto_persona.png';
import trellouserstory from '../assets/images/trello_userstory.png';
import trellouserflow from '../assets/images/trello_userflow.png';
import trellodesignsystem from '../assets/images/trello_design_system.png';
import trellolofi from '../assets/images/trello_low_fi_wireframes.png';
import trellohifi from '../assets/images/trello_hi_fi_wireframes.png';
import trellousability1 from '../assets/images/trello_usability_testing_result1.png';
import trelloinsight1 from '../assets/images/trello_insight1.png';
import trelloiteration1 from '../assets/images/trello_iteration1.png';
import trelloiteration2 from '../assets/images/trello_iteration2.png';
import trelloiteration3 from '../assets/images/trello_iteration3.png';
import trelloiteration4 from '../assets/images/trello_iteration4.png';
import trelloiteration5 from '../assets/images/trello_iteration5.png';


const Trello = () => {
    const headerHeight = 84;
    const sidebarItems = [
        'Overview',
        'Problem',
        'Define',
        'Design',
        'Iteration',
        'Product',
        'Next Step'
    ];

const itemStyle = {
        color: '#0455CC',
    };
    
    return (
        <div className="trello work-page">
            <div className="work-hero" style={{ marginTop: '100px' }}>
                <img src={trelloimage} />
            </div>

            <div className="sidebar sidebar-sticky">
                <Sidebar items={sidebarItems} />
            </div>
            <div className="work-body" >
                <section key={0} className="section">
                    <div className='text-container'>

                        <h2 id="overview" className="section-title" style={{ color: '#0455CC' }}>Overview</h2>
                        <h3>Trello</h3>
                        <p>Trello is a collaborative project management tool that organizes tasks and information through a visual board and card system, fostering teamwork and transparency.</p>
                        <div className="team-member-container d-flex">
                            <div className="d-flex flex-column w-50">
                                <h4 className="team-member-name"style={{ color: '#0455CC', fontSize: '20px' }}>Timeline</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>8 Weeks</li>

                                </ul>
                            </div>
                            <div className="d-flex flex-column w-50">

                                <h4 className="team-member-name"style={{ color: '#0455CC', fontSize: '20px' }}>Role</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>Project Management</li>
                                    <li>UX/UI Design</li>
                                    <li>UX Research</li>
                                </ul>
                            </div>
                            <div className="d-flex flex-column w-50">

                                <h4 className="team-member-name"style={{ color: '#0455CC', fontSize: '20px' }}>Tools</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>Figma</li>
                                    <li>Adobe Illustrator</li>
                                    <li>Trello</li>
                                </ul>
                            </div>
                        </div>
                        <h3>Project Objective</h3>
                        <p>Conceptualize, design, and implement a feature within Trello that significantly enhances user engagement and overall experience, focusing on intuitive functionality, streamlined collaboration, and improved usability to elevate the platform's effectiveness in project management.</p>
                        <h3>Design Thinking Process</h3>
                        <div className="image-inside" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={trellodesign} style={{ width: '70%', height: 'auto' }}/>
                        </div>
                    </div>
                </section>

                <section key={1} className="section lightblue-bg">
                    <div className='text-container'>
                        <h2 id="problem" className="section-title" style={{ color: '#0455CC' }}>Problem</h2>
                        <p style={{ paddingTop: '30px' }}>
                        Trello users currently face challenges when manually creating cards in Trello after accessing information from their Learning Management System, resulting in a time-consuming and error-prone workflow
                        </p>
                    </div>
                </section>

                <section key={2} className="section">
                    <div className='text-container'>
                        <h2 id="define" className="section-title" style={{ color: '#0455CC' }}>Define</h2>
                        <h3>Proto-Persona</h3>
                        <div className="image-inside">
                            <img src={trelloprotopersona}
                                style={{
                                    borderRadius: '18px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Adjust the values for your desired shadow
                                }}
                            />
                        </div>
                    </div>
                    <div className='grey-bg'>
                        <div className='text-container'>
                            <h3>Emily’s User Story & Flow</h3>
                            <img src={trellouserstory} style={{ width: '100%', height: 'auto' }} />
                            <div className='text-container' style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={trellouserflow} style={{ width: '100vw', height: 'auto' }} />
                            </div>
                        </div>
                    </div>

                </section>

                <section key={3} className="section">
                    <div className='text-container' style={{ textAlign: 'left' }}>
                        <h2 id="design" className="section-title" style={{ color: '#0455CC' }}>Design</h2>
                        <h3>Design System</h3>
                        <div className="image-outside" style={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                                src={trellodesignsystem}
                                style={{
                                    borderRadius: '18px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                            />
                        </div>
                        <h3>Low-Fidelity Wireframes</h3>
                        <div className="image-inside" style={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                                src={trellolofi}
                                style={{
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                            />
                        </div>
                    </div>
                    <div className='grey-bg'>
                        <div className='text-container'>
                            <h3>High Fidelity Wireframes</h3>
                            <div className="image-inside">
                                <img src={trellohifi} />
                            </div>
                            <p className="comment" style={{
                                textAlign: 'center',
                                color: 'rgba(128, 128, 128, 0.8)', // Medium gray with alpha
                                fontStyle: 'italic'
                            }}> These high-fidelity wireframes demonstrate the evolution from the above low-fidelity version</p>
                        </div>

                    </div>

                </section>

                <section key={4} className="section">
                    <div className='text-container'>
                        <h2 id="iteration" className="section-title" style={{ color: '#0455CC' }}>Iteration</h2>
                        <h3>Methodology</h3>
                        <div className="d-flex flex-column w-50">
                            <p4 className="methodology-3" style={{ fontSize: '20px' }}>Usability Testing I</p4>
                            <ul style={{ fontSize: '20px' }}>
                                <li><b>Number of Participants:</b>  5</li>
                                <li><b>Ratio:</b>  1 interviewer : 1 participant </li>
                                <li><b>Interview Time:  </b>30 - 45 minutes minutes</li>
                            </ul>
                        </div>
                    </div>
                        <div className='grey-bg'>
                            <div className='text-container'>
                                <h3>Quotes & Insights</h3>
                                <img
                                    src={trellousability1}
                                    style={{
                                        width: '100%',
                                        height: 'auto',
                                        borderRadius: '18px',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}/>                                                        
                            </div>
                        </div>
                        <div className="image-inside">
                            <img
                                    src={trelloinsight1}
                                    style={{
                                        width: '40%',
                                        height: 'auto',
                                        borderRadius: '18px',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                        display: 'block',
                                    }}/>
                        </div>
                        <div className='text-container'>
                                <h3>Key Refinements</h3>
                                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="4" aria-label="Slide 5"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img src={trelloiteration1} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={trelloiteration2} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={trelloiteration3} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={trelloiteration4} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={trelloiteration5} class="d-block w-100" alt="..." />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" >
                                <span className="carousel-control-prev-icon" aria-hidden="true" ></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" >
                                <span className="carousel-control-next-icon" aria-hidden="true" ></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                        </div>
                        
                </section>

                <section key={5} className="section lightblue-bg">
                    <div className='text-container'>
                        <h2 id="product" className="section-title" style={{ color: '#0455CC' }}>Product</h2>
                        <div className='text-container'>
                        <iframe style={{ paddingTop: '30px' }} width="100%" height="800" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F624cATR1HaKqhjHMKNplpJ%2FTrello-Project-File%252FPrototype%3Ftype%3Ddesign%26node-id%3D1-2378%26t%3DcmN3a8EpnFBiQqz0-1%26scaling%3Dscale-down-width%26page-id%3D1%253A3%26starting-point-node-id%3D1%253A2378%26mode%3Ddesign" allowfullscreen></iframe>
                    </div>

                    </div>
                </section>
                <section key={6} className="section">
                    <div className='text-container'>
                        <h2 id="next-step" className="section-title" style={{ color: '#0455CC' }}>Next Step</h2>
                        <ul>
                            <li><p style={{ paddingTop: '30px' }}>Continue gathering user feedback to refine the design further</p></li>
                            <li><p>Conduct an accessibility audit to ensure the design complies with accessibility standards</p></li>
                            <li><p>Optimize the design for mobile screen size and resolution</p></li>
                            <li><p>Conduct user research to validate and prioritize new features</p></li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Trello;