// Spotify.js
import React from 'react';
import Sidebar from '../components/FlexFit/Sidebar';
// Import your image
import spotifyimage from '../assets/images/spotify-overview.png';
import spotifyinsights from '../assets/images/spotify-keyinsights.png';
import spotifyuserflow from '../assets/images/spotify-userflow.png';
import trellodesign from '../assets/images/trello_design_thinking_system.png';
import spotifysketch from '../assets/images/spotify-crazy8.png';
import spotifymidfi from '../assets/images/spotify-mid-fi.png';
import spotifyhifi from '../assets/images/spotify-hi-fi.png';
import spotifytested from '../assets/images/spotify-tested.png';
import spotifytakeaways from '../assets/images/spotify-takeaways.png';

import trelloinsight1 from '../assets/images/trello_insight1.png';
import spotifyiteration1 from '../assets/images/spotify-iteration1.png';
import spotifyiteration2 from '../assets/images/spotify-iteration2.png';
import spotifyiteration3 from '../assets/images/spotify-iteration3.png';


const Spotify = () => {
    const headerHeight = 84;
    const sidebarItems = [
        'Overview',
        'Problem',
        'Research',
        'Design',
        'Iteration',
        'Product',
        'Next Step'
    ];

const itemStyle = {
        color: '#1DB954',
    };
    
    return (
        <div className="trello work-page">
            <div className="work-hero" style={{ marginTop: '100px' }}>
                <img src={spotifyimage} />
            </div>

            <div className="sidebar sidebar-sticky">
                <Sidebar items={sidebarItems} />
            </div>
            <div className="work-body" >
                <section key={0} className="section">
                    <div className='text-container'>

                        <h2 id="overview" className="section-title" style={{ color: '#1DB954' }}>Overview</h2>
                        <h3>Spotify</h3>
                        <p>Spotify is a digital music streaming service that offers users access to millions of songs, podcasts, and other audio content for on-demand listening.</p>
                        <div className="team-member-container d-flex">
                            <div className="d-flex flex-column w-50">
                                <h4 className="team-member-name"style={{ color: '#1DB954', fontSize: '20px' }}>Timeline</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>8 Weeks</li>

                                </ul>
                            </div>
                            <div className="d-flex flex-column w-50">

                                <h4 className="team-member-name"style={{ color: '#1DB954', fontSize: '20px' }}>Role</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>Project Management</li>
                                    <li>UX/UI Design</li>
                                    <li>UX Research</li>
                                    <li>Prototyping</li>
                                </ul>
                            </div>
                            <div className="d-flex flex-column w-50">

                                <h4 className="team-member-name"style={{ color: '#1DB954', fontSize: '20px' }}>Tools</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>Figma</li>
                                    <li>FigJam</li>
                                    <li>Slides</li>
                                </ul>
                            </div>
                        </div>
                        <h3>Project Objective</h3>
                        <p>Our project's primary objective was to brainstorm and implement a brancd new feature aimed at enhancing user engagement and overall experience on Spotify, thereby enriching the platform's functionality and appeal.</p>
                        <h3>Design Thinking Process</h3>
                        <div className="image-inside" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <img src={trellodesign} style={{ width: '70%', height: 'auto' }}/>
                        </div>
                    </div>
                </section>

                <section key={1} className="section lightgreen-bg">
                    <div className='text-container'>
                        <h2 id="problem" className="section-title" style={{ color: '#1DB954' }}>Problem</h2>
                        <p style={{ paddingTop: '30px' }}>
                        <b>Our mission</b> is to provide music streaming service in efficient and convenience ways for music lovers. </p>

                        <p><b>We have observed </b> that customers have busy lives and often struggle to figure out what type of music they want to listen to which is causing them to stop using music streaming app and look for alternative. 
                        </p>
                        <p> <b>Our goal </b>is to add AI features to build customized playlists by situation or mood so our customers are confident in their decision to use Spotify and more likely to upgrade their account to premium based on increased engagement metrics.
                        </p>
                    </div>
                </section>

                <section key={2} className="section">
                    <div className='text-container'>
                        <h2 id="Research" className="section-title" style={{ color: '#1DB954' }}>Research</h2>
                        <h3>Methodology</h3>
                        <div className="d-flex flex-column w-50">
                            <p4 className="methodology-3" style={{ fontSize: '20px' }}>Desk Research & User Interview</p4>
                            <ul style={{ fontSize: '20px' }}>
                                <li><b>Number of Participants:</b>  7</li>
                                <li><b>Ratio:</b>  1 interviewer : 1 participant </li>
                                <li><b>Interview Time:  </b>30 - 45 minutes minutes</li>
                            </ul>
                        </div>
                        <h3 style={{ marginBottom: '-30px' }}>Key Findings</h3>
                        <div className="image-inside" style={{ marginTop: '0', marginBottom: '-60px' }}>
                            <img src={spotifyinsights} />
                        </div>
                        <div className='text-container'>
                            <h3>User Flow</h3>
                            <p>Based on our research, we have developed a user flow where users can experience AI assistance feature throughout Spotify.</p>
                            <div className='text-container' style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={spotifyuserflow} style={{ width: '100vw', height: 'auto' }} />
                            </div>
                        </div>
                    </div>

                </section>

                <section key={3} className="section">
                    <div className='text-container' style={{ textAlign: 'left' }}>
                        <h2 id="design" className="section-title" style={{ color: '#1DB954' }}>Design</h2>
                        <h3>Sketches | Crazy 8's</h3>
                        <p>We begin by illustrating the Crazy 8's of this project, which showcase the fundamental concepts and ideas for incorporating the AI assistance feature into Spotify.</p>  
                    </div>        
                            <div className='grey-bg'>
                                <div className='text-container'>
                                    <img
                                        src={spotifysketch}
                                        style={{
                                            width: '80%',
                                            height: 'auto',
                                            borderRadius: '18px',
                                            display: 'block',
                                            margin: 'auto',
                                        }}/>                                                        
                                </div>
                            </div>

                        <div className='text-container'>
                        <h3>Mid-Fi Wireframes</h3>
                        <p>With a clearer understanding of the design direction, we translated the refined sketches into mid-fidelity wireframes. This involved transitioning from the conceptual stage to a more structured representation of the user interface, incorporating elements such as navigation, content placement, and interaction points.</p>
                                    <img
                                        src={spotifymidfi}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            display: 'block',
                                            margin: 'auto',
                                            marginTop: '50px',
                                        }}/>                                                        
                                </div>

                        <div className='text-container'>
                        <h3>Hi-Fi Wireframes</h3>
                                 <img
                                        src={spotifyhifi}
                                        style={{
                                            width: '100%',
                                            height: 'auto',
                                            display: 'block',
                                            margin: 'auto',
                                            marginTop: '50px',
                                }}/>     
                            <p className="comment" style={{
                                textAlign: 'center',
                                color: 'rgba(128, 128, 128, 0.8)', // Medium gray with alpha
                                fontStyle: 'italic',
                                marginTop: '50px'
                            }}> These high-fidelity wireframes demonstrate the evolution from the above mid-fidelity version</p>
                        </div>
                </section>

                <section key={4} className="section">
                    <div className='text-container'>
                        <h2 id="iteration" className="section-title" style={{ color: '#1DB954' }}>Iteration</h2>
                        <h3>Methodology</h3>
                        <div className="d-flex flex-column w-50">
                            <p4 className="methodology-3" style={{ fontSize: '20px' }}>Usability Testing</p4>
                            <ul style={{ fontSize: '20px' }}>
                                <li><b>Number of Participants:</b>  6</li>
                                <li><b>Ratio:</b>  1 interviewer : 1 participant </li>
                                <li><b>Interview Time:  </b>45 minutes minutes</li>
                            </ul>
                        </div>
                    </div>
                            <div className='text-container'>
                                <h3>Key Insights/Takeaways</h3>                                                  
                            </div>
                            <img
                                    src={spotifytested}
                                    style={{
                                        width: '50%',
                                        height: 'auto',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}/>      
                        <div className="grey-bg">
                            <img
                                    src={spotifytakeaways}
                                    style={{
                                        width: '50%',
                                        borderRadius: '18px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Adjust the values for your desired shadow
                                        display: 'block',
                                    }}/>
                        </div>
                        <div className='text-container'>
                                <h3>Key Refinements</h3>
                                <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img src={spotifyiteration1} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={spotifyiteration2} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={spotifyiteration3} class="d-block w-100" alt="..." />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" >
                                <span className="carousel-control-prev-icon" aria-hidden="true" ></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" >
                                <span className="carousel-control-next-icon" aria-hidden="true" ></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                        </div>
                        
                </section>

                <section key={5} className="section lightgreen-bg">
                    <div className='text-container'>
                        <h2 id="product" className="section-title" style={{ color: '#1DB954' }}>Product</h2>
                        <div className='text-container'>
                        <iframe style={{  paddingTop: '30px' }} width="100%" height="800" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FzLU6ZIyLS2IjROAtCwPFDt%2FSpotify-Final-Prototype%3Ftype%3Ddesign%26node-id%3D1-469%26t%3D6yUjAPtnkeMpGB0c-1%26scaling%3Dscale-down%26page-id%3D0%253A1%26starting-point-node-id%3D1%253A469%26mode%3Ddesign" allowfullscreen></iframe>
                    </div>

                    </div>
                </section>
                <section key={6} className="section">
                    <div className='text-container'>
                        <h2 id="next-step" className="section-title" style={{ color: '#1DB954' }}>Next Step</h2>
                        <ul>
                            <li><p style={{  paddingTop: '30px' }}>Continue gathering user feedback to refine the design further</p></li>
                            <li><p>Conduct an accessibility audit to ensure the design complies with accessibility standards</p></li>
                            <li><p>Conduct user research to validate and prioritize new features</p></li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Spotify;
