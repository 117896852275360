// Sidebar.js

import React from 'react';
import './Sidebar.scss';

const Sidebar = ({ items, activeItem }) => {
  return (
    <div className="sidebar-container">
      <ul>
        {items.map((item, index) => {
          const itemId = item.toLowerCase().replace(/\s+/g, '-');
          const isActive = activeItem === item;

          return (
            <li key={index}>
              <a
                href={`#${itemId}`}
                id={`${itemId}-anchor`}
                className={isActive ? 'active' : ''}
              >
                {item}
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Sidebar;
