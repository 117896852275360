// Hero.js

import React from 'react';
import './Hero.scss'; // Import the corresponding styles
import heroVideo from './../../assets/images/hero-video.mp4';

const Hero = () => {
    const str = 'Discover projects that showcase my commitment to user-centric solutions, innovative problem-solving, and a touch of creativity.';

    return (
        <div className="hero-video-container">
            <video className="hero-video" autoPlay loop muted>
                <source src={heroVideo} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="hero-content">
                <h1 className="hero-title">Hey there! I’m Yura Molitor</h1>
                <p className="hero-subtitle">UX | UI Designer</p>
                <p className="hero-body waviy" style={{ marginTop: '200px', fontSize: '22px' }}>
                    {str.split(' ').map((word, index) => (
                        <span style={{ '--i': index }} className="wave" key={word + index.toString()}>
                            {index > 0 ? ' ' : null}{/* Add space only if not the first word */}
                            {word}
                        </span>
                    ))}
                </p>
            </div>
        </div>
    );
};

export default Hero;