// Works.js

import React from 'react';
import './Works.scss'; // Import the corresponding styles
import WorkCard from './WorkCard'; // Import the new component
import work01 from '../../assets/images/work-1.png'; // FLEXFIT
import work02 from '../../assets/images/work-2.png'; // TRELLO
import work03 from '../../assets/images/work-3.png'; // SPOTIFY

const Works = () => {
    return (
        <section className="works" id="works">
            <h2 className="works-title">Works</h2>
            <div className="works-list">
                {/* First Card */}
                <WorkCard
                    imageUrl={work01}
                    title={
                        <>
                            Seamless Fitness: <br /> The FLEXFIT App Design Story
                        </>
                    }
                    caseStudy="Case Study"
                    buttonUrl="/flex-fit"
                    
                />

                {/* Second Card */}
                <WorkCard
                    imageUrl={work02}
                    title={
                        <>
                            Empowering Collaboration: <br /> Trello Enhancement Story
                        </>
                    }
                    caseStudy="Case Study"
                    buttonUrl="/trello"
                />

                {/* Third Card */}
                <WorkCard
                    imageUrl={work03}
                    title={
                        <>
                            AI-Powered Harmony: <br /> Spotify's Enhanced Music Discovery
                        </>
                    }
                    caseStudy="Case Study"
                    buttonUrl="/spotify"
                />
            </div>
        </section>
    );
};

export default Works;
