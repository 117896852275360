// WorkCard.js

import React from 'react';
import Button from 'react-bootstrap/Button';
import './Works.scss'; // Import the CSS file with the styles

const WorkCard = ({ imageUrl, title, caseStudy, buttonUrl, hide }) => {

    return (
        <div className="card-container" style={{ marginBottom: '0' }}>
            <img src={imageUrl} className="image" />

            <div className="text-container">
                <h2>
                    {title}
                    <span className="title-line-break" />
                </h2>
                <p className="case-study" style={{ marginBottom: '50px' }}>{caseStudy}</p>
                {/* Adjust the margin-bottom value as needed */}
                {!hide ? <Button variant="custom" className="work-card-button" href={buttonUrl}>
                    View More
                </Button> : <Button disabled variant="custom" className="work-card-button" >
                    Coming Soon
                </Button> }
            </div>
        </div>
    );
};

export default WorkCard;
