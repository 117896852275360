import React from 'react';
import './Footer.scss';
import mail from './../../assets/images/icon-mail.svg';
import linkedIn from './../../assets/images/icon-linkedin.svg';

const Footer = () => {
    return (
        <div className='footer d-flex flex-column'>
            <div>
            <a href="mailto:yurakim08920@gmail.com" target='_blank'>
                <img src={mail} />
            </a>
            <a href="https://www.linkedin.com/in/yura-molitor/" target='_blank'>
                <img src={linkedIn} />
            </a></div>
            <span className='copyright'>© 2024 Yura Molitor. All rights reserved.</span>
        </div>
    );
}

export default Footer;

