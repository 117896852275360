// FlexFit.js
import React from 'react';
import Sidebar from '../components/FlexFit/Sidebar';
// Import your image
import flexfitimage from '../assets/images/flexfit-overview.png';
import flexfitdesign from '../assets/images/flexfit-design-process.png';
import flexfitinsights from '../assets/images/flexfit-insights.png';
import flexfitpersona from '../assets/images/flexfit-persona.png';
import flexfitjourney from '../assets/images/flexfit-user-journey.png';
import flexfitdesignsystem from '../assets/images/flexfit-design-system.png';
import flexfitlofiwireframe from '../assets/images/flexfit-lo-fi-wireframes.png';
import flexfithifiwireframe from '../assets/images/flexfit-hi-fi-wireframes.png';
import flexfitapplewatch from '../assets/images/flexfit-applewatch.png';
import iteration1 from '../assets/images/flexfit-iteration1.png';
import iteration2 from '../assets/images/flexfit-iteration2.png';
import iteration3 from '../assets/images/flexfit-iteration3.png';
import iteration4 from '../assets/images/flexfit-iteration4.png';


const FlexFit = () => {
    const headerHeight = 84;
    const sidebarItems = [
        'Overview',
        'Problem',
        'Research',
        'Design',
        'Iteration',
        'Product',
        'Next Step'
    ];

    return (
        <div className="flex-fit work-page">
            <div className="work-hero">
                <img src={flexfitimage} />
            </div>

            <div className="sidebar sidebar-sticky">
                <Sidebar items={sidebarItems} />
            </div>
            <div className="work-body" >
                <section key={0} className="section">
                    <div className='text-container'>

                        <h2 id="overview" className="section-title">Overview</h2>
                        <h3>FLEXFIT</h3>
                        <p>FLEXFIT is a perfect fitness app for remote workers with personalized routines and a rewards system, fostering motivation and engagement.</p>
                        <div className="team-member-container d-flex">
                            <div className="d-flex flex-column w-50">
                                <h4 className="team-member-name" style={{ fontSize: '20px' }}>Timeline</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>8 Weeks</li>

                                </ul>
                            </div>
                            <div className="d-flex flex-column w-50">

                                <h4 className="team-member-name" style={{ fontSize: '20px' }}>Role</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>Project Manager</li>
                                    <li>UX Design</li>
                                    <li>UX Research</li>
                                </ul>
                            </div>
                            <div className="d-flex flex-column w-50">

                                <h4 className="team-member-name" style={{ fontSize: '20px' }}>Tools</h4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li>Figma</li>
                                    <li>Adobe Illustrator</li>
                                    <li>Trello</li>
                                    <li>Miro</li>
                                </ul>
                            </div>
                        </div>
                        <h3>Project Objective</h3>
                        <p>Design a user-friendly mobile app for remote workers, enabling them to create personalized workout routines. The app will feature exercises with time and difficulty details, encourage routine sharing, and include a nudge feature for consistent usage. The primary objective is to enhance user engagement by understanding and implementing elements that maintain interest and create a sticky, motivating experience.</p>
                        <h3>Design Thinking Process</h3>
                        <div className="image-inside">
                            <img src={flexfitdesign} />
                        </div>
                    </div>
                </section>

                <section key={1} className="section purple-bg">
                    <div className='text-container'>
                        <h2 id="problem" className="section-title">Problem</h2>
                        <p></p>
                        <p style={{ paddingTop: '30px' }}><b>Our mission</b> is to create a workout app for work-from-home professionals, helping them maintain consistent exercise routines, personalized to their fitness goals.</p>
                        <p><b>We've noticed</b> a common challenge among our users, a lack of commitment to remote workouts, leading to reduced app usage.</p>
                        <p><b>Our objective</b> is to offer innovative solutions to keep users engaged and motivated, ensuring they regularly use the app to achieve their fitness goals.</p>

                    </div>
                </section>

                <section key={2} className="section">
                    <div className='text-container'>
                        <h2 id="research" className="section-title">Research</h2>
                        <h3>Methodology</h3>
                        <div className="team-member-container d-flex">
                            <div className="d-flex flex-column w-50">
                                <h4 className="team-member-name" style={{ fontSize: '20px' }}>Desk Research</h4>

                                <p4 className="methodology-1" style={{ fontSize: '20px' }}>Competitive Analysis</p4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li><b>4 Direct Competitors</b></li>
                                    <li><b>4 Indirect Competitors</b></li>

                                </ul>
                            </div>
                            <div className="d-flex flex-column w-50">
                                <h4 className="team-member-name" style={{ fontSize: '20px' }}>Qualitative Research</h4>

                                <p4 className="methodology-2" style={{ fontSize: '20px' }}>User Interview</p4>
                                <ul style={{ fontSize: '18px' }}>
                                    <li><b>Number of Participants:</b>  6</li>
                                    <li><b>Ratio:</b>  1 interviewer : 1 participant </li>
                                    <li><b>Interview Time:  </b>30 - 45 minutes</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className='grey-bg'>
                    <div className='text-container'>
                        <h3>Key Findings/Insights</h3>
                        <ul>
                            <li><p>Users highly value <b>customization/personalization</b> options in apps</p></li>
                            <li><p>Customization is crucial due to <b>varying schedules</b> and <b>individual factors</b></p></li>
                            <li><p><b>Common drawback:</b> Lack of nutrition/meal knowledge within the apps</p></li>
                            <li><p>Ease of workouts is a concern, prompting users to <b>explore other options</b></p></li>
                        </ul>
                        <div className="image-inside">
                            <img src={flexfitinsights} />
                        </div>
                    </div>
                    </div>

                    <div className='text-container'>
                        <h3>User Persona: Tonya</h3>
                        <p>Based on our research, we crafted detailed user persona, Tonya, to understand the diverse needs of FLEXFIT target audience, ensuring the final design would cater to their specific requirements.</p>
                        <div className="image-inside">
                            <img src={flexfitpersona}
                                style={{
                                    borderRadius: '18px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Adjust the values for your desired shadow
                                }}
                            />
                        </div>
                    </div>
                    <div className='grey-bg'>
                        <div className='text-container'>

                            <h3>Tonya's User Journey</h3>
                            <p>Mapping the end-to-end Tonya's experience helped us identify touchpoints, pain points, and moments of delight, providing insights for creating a user-centric design.</p>
                            <div className="image-inside">
                                <img src={flexfitjourney}
                                    style={{
                                        borderRadius: '18px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' // Adjust the values for your desired shadow
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                <section key={3} className="section">
                    <div className='text-container' style={{ textAlign: 'left' }}>
                        <h2 id="design" className="section-title">Design</h2>
                        <h3>Design System</h3>
                        <p>FLEXFIT Design System ensured consistency in colors, typography, and components.</p>
                        <div className="image-outside" style={{ display: 'flex', justifyContent: 'center' }}>
                            <img
                                src={flexfitdesignsystem}
                                style={{
                                    borderRadius: '18px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                }}
                            />
                        </div>
                    </div>

                    <div className='text-container'>
                        <h3>Low Fidelity Wireframes</h3>
                        <p>Creating low-fidelity wireframes allowed us to focus on layout, functionality, and user flow, providing a blueprint for the subsequent stages of the design process.</p>
                        <div className="image-inside">
                            <img src={flexfitlofiwireframe} />
                        </div>
                        <p className="comment" style={{
                            textAlign: 'center',
                            color: 'rgba(128, 128, 128, 0.8)', // Medium gray with alpha
                            fontStyle: 'italic'
                        }}> These wireframes showcase several prominent features of the FLEXFIT</p>
                    </div>
                    <div className='grey-bg'>
                        <div className='text-container'>
                            <h3>High Fidelity Wireframes</h3>
                            <div className="image-inside">
                                <img src={flexfithifiwireframe} />
                            </div>
                            <p className="comment" style={{
                                textAlign: 'center',
                                color: 'rgba(128, 128, 128, 0.8)', // Medium gray with alpha
                                fontStyle: 'italic'
                            }}> These high-fidelity wireframes demonstrate the evolution from the above low-fidelity version</p>
                            <div className="image-inside">
                                <img src={flexfitapplewatch} />
                            </div>
                        </div>

                    </div>
                </section>

                <section key={4} className="section">
                    <div className='text-container'>
                        <h2 id="iteration" className="section-title">Iteration</h2>
                        <p>Starting with usability testing, I gathered valuable user feedback to identify areas for improvement. This iterative process allowed me to refine the design, address user concerns, and enhance the overall user experience.</p>
                        <h3>Methodology</h3>
                        <div className="d-flex flex-column w-50">
                            <p4 className="methodology-3" style={{ fontSize: '20px' }}>Usability Testing</p4>
                            <ul style={{ fontSize: '18px' }}>
                                <li><b>Number of Participants:</b>  5</li>
                                <li><b>Ratio:</b>  2 interviewer : 1 participant </li>
                                <li><b>Interview Time:  </b>60 minutes minutes</li>
                            </ul>
                        </div>
                        <h3>Key Findings/Refinements</h3>
                        <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                            <div class="carousel-indicators">
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                                <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3" aria-label="Slide 4"></button>
                            </div>
                            <div class="carousel-inner">
                                <div class="carousel-item active">
                                    <img src={iteration1} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={iteration2} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={iteration3} class="d-block w-100" alt="..." />
                                </div>
                                <div class="carousel-item">
                                    <img src={iteration4} class="d-block w-100" alt="..." />
                                </div>
                            </div>
                            <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev" >
                                <span className="carousel-control-prev-icon" aria-hidden="true" ></span>
                                <span className="visually-hidden">Previous</span>
                            </button>
                            <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next" >
                                <span className="carousel-control-next-icon" aria-hidden="true" ></span>
                                <span className="visually-hidden">Next</span>
                            </button>
                        </div>
                    </div>

                </section>
                <section key={5} className="section purple-bg">
                    <div className='text-container'>
                        <h2 id="product" className="section-title">Product</h2>
                        <iframe style={{ paddingTop: '30px' }} width="100%" height="800" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FdtxabTKGX2asIQOtl2xCUe%2FFLEXFIT-Final-Prototype%3Fkind%3Dproto%26node-id%3D2564-11622%26page-id%3D2564%253A11107%26scaling%3Dscale-down%26starting-point-node-id%3D2564%253A11622%26t%3DzhlEoMo44D13C46g-1%26type%3Ddesign%26mode%3Ddesign" allowfullscreen></iframe>
                    </div>
                </section>
                <section key={6} className="section">
                    <div className='text-container'>
                        <h2 id="next-step" className="section-title">Next Step</h2>
                        <ul>
                            <li><p style={{ paddingTop: '30px' }}>Conduct additional rounds of usability testing with the updated prototype</p></li>
                            <li><p>Verify the positive impact of implemented changes on user experience</p></li>
                            <li><p>Identify and address any new issues introduced during the iteration</p></li>
                            <li><p>Identify areas for improvement or any emerging issues based on user feedback</p></li>
                        </ul>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default FlexFit;
