import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Import Link and useLocation from React Router
import logo from './../../assets/images/logo.svg';
import resume from './../../assets/yuram-resume2024.pdf';
import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {
  const [activeNavItem, setActiveNavItem] = useState('home');
  const location = useLocation(); // Get the current location using useLocation

  const handleNavItemClick = (item) => {
    setActiveNavItem(item);
    if (item === 'home') {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      const targetElement = document.getElementById(item);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }
  };

  useEffect(() => {
    // Use the pathname from the current location to determine the active nav item
    const currentPath = location.pathname;
    if (currentPath === '/flex-fit' || currentPath === '/trello' || currentPath === '/spotify') {
      setActiveNavItem('works');
    }
  }, [location.pathname]); // Listen for changes to location.pathname

  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/#home" onClick={() => handleNavItemClick('home')}>
          <img src={logo} alt="Logo" className="logo" />
        </Link>
      </div>
      <nav className="nav">
        <ul>
          <li className={activeNavItem === 'home' ? 'active' : ''}>
            <Link to="/#home" onClick={() => handleNavItemClick('home')}>
              Home
            </Link>
          </li>
          {/* <li className={activeNavItem === 'about' ? 'active' : ''}>
            <Link to="/#about" onClick={() => handleNavItemClick('about')}>
              About
            </Link>
          </li> */}
          <li className={activeNavItem === 'works' ? 'active' : ''}>
            <Link to="/#works" onClick={() => handleNavItemClick('works')}>
              Works
            </Link>
          </li>
          <li className={activeNavItem === 'resume' ? 'active' : ''}>
            <a href={resume} download="yuram-resume.pdf">
              Resume
            </a>
          </li>
          <li className={activeNavItem === 'contact' ? 'active' : ''}>
            <Link to="/#contact" onClick={() => handleNavItemClick('contact')}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;
