import Hero from '../../src/components/Hero/Hero';
import Works from '../../src/components/Works/Works';
import Button from 'react-bootstrap/Button';

function Home() {
  return (
    <div className="App">
      <Hero />

      {/* <section className='about home-section' id='about'>
        <h2 className="about-title">About</h2>
        </section> */}

      <Works />
      <section className='contact home-section' id='contact'>
        <h2 className="contact-title">Contact</h2>
        <div className='d-flex flex-column align-items-center '>
        <p style={{ paddingBottom: '30px', fontSize: '18px'}}>Have a question or project in mind? Let's chat! </p>
        <Button variant="custom" className="work-card-button" href="" >
                    Contact
                </Button></div>
        </section>
    </div>
  );
}

export default Home;